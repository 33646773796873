<template>
  <div class="elv-ledger-auxiliary-code-table-container">
    <div class="elv-ledger-auxiliary-code-table-box">
      <el-table
        v-loading="props.tableLoading"
        :data="props.tableData?.list"
        border
        height="100%"
        class="elv-auxiliary-code-table"
        header-cell-class-name="elv-auxiliary-code-table-header__cell"
        header-row-class-name="elv-auxiliary-code-table-header"
        cell-class-name="elv-auxiliary-code-table-row__cell"
        @row-click="onCellClicked"
      >
        <el-table-column
          :label="t('title.auxiliaryCodeName')"
          width="280"
          class-name="elv-auxiliary-code-table-row__cell-name"
        >
          <template #default="{ row }">
            {{ row.name }}
          </template>
        </el-table-column>
        <el-table-column :label="t('common.type')" width="220">
          <template #default="{ row }">
            {{ transformI18n(auxiliaryCodeTypeData(row.type).value?.label) }}
          </template>
        </el-table-column>
        <el-table-column :label="t('title.auxiliaryCodeValue')" class-name="elv-auxiliary-code-table-row__cell-value">
          <template #default="{ row }">
            <p v-if="row.type !== 'ITEM'">
              {{ row.type === 'ENTITY_ACCOUNT' ? 'all treasury account' : 'all counterparty' }}
            </p>
            <span v-for="(item, index) in row?.auxiliaryItems" v-else :key="item?.auxiliaryItemId"
              >{{ item.value }}
              <template v-if="index !== row?.auxiliaryItems?.length - 1"> / </template>
            </span>
          </template>
        </el-table-column>
        <el-table-column label="" width="116" class-name="elv-auxiliary-code-table-row__operating" align="center">
          <template #default="{ row }">
            <template v-if="row.type !== 'ENTITY_ACCOUNT'">
              <SvgIcon
                name="sources-edit"
                width="18"
                fill="#838D95"
                height="18"
                @click="onEditAuxiliaryCodeType(row)"
              />
              <SvgIcon
                name="sources-delete"
                width="18"
                height="18"
                fill="#838D95"
                @click="onDeleteAuxiliaryCodeType(row)"
              />
            </template>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <elv-pagination
      class="elv-journals-list-pagination"
      size-popper-class="elv-journals-pagination__sizes-popper"
      :limit="props.auxiliaryCodeTypeParams.limit"
      :current-page="props.auxiliaryCodeTypeParams.page"
      :total="props.tableData?.total"
      @current-change="onChangePage"
      @size-change="onChangePageSize"
    />
    <ElvMessageBox
      ref="deleteMessageBoxRef"
      :show-cancel-button="true"
      :confirm-button-text="t('button.delete')"
      :cancel-button-text="t('button.cancel')"
      :title="`${t('button.delete')} ${currentData?.name}`"
      :loading="deleteLoading"
      @onConfirmEvent="onConfirmDeleteAuxiliaryCodeType"
      @onCancelEvent="onCancelDeleteAuxiliaryCodeType"
    >
      <template #content>
        <span class="elv-confirm-journal-configure-business-event-journal">{{
          t('message.deleteAuxiliaryCodeTypeInfo', {
            type: transformI18n(auxiliaryCodeTypeData(currentData?.type).value?.label),
            name: currentData?.name
          })
        }}</span></template
      >
    </ElvMessageBox>
  </div>
</template>

<script setup lang="ts">
import { find } from 'lodash-es'
import { useI18n } from 'vue-i18n'
import LedgerApi from '@/api/LedgerApi'
import { ElMessage } from 'element-plus'
import { transformI18n } from '@/i18n/index'
import { useEntityStore } from '@/stores/modules/entity'
import { useComputedHook } from '@/hooks/useComputedHook'
import { auxiliaryCodeTypeOptions } from '@/config/reports/index'

const props = defineProps({
  tableData: {
    type: Object,
    required: true
  },
  tableLoading: {
    type: Boolean,
    default: false
  },
  tableHeight: {
    type: String,
    required: true
  },
  auxiliaryCodeTypeParams: {
    type: Object,
    required: true
  }
})

const route = useRoute()
const { t } = useI18n()
const entityStore = useEntityStore()
const emit = defineEmits(['onEditAuxiliaryCodeType', 'onResetList', 'onChangePage', 'onChangePageSize'])

const currentData = ref<any>({})
const deleteMessageBoxRef = useTemplateRef('deleteMessageBoxRef')
const deleteLoading = ref(false)

const auxiliaryCodeTypeData: any = useComputedHook((type: string) => {
  return find(auxiliaryCodeTypeOptions, { value: type })
})

const entityId = computed(() => {
  return route.params?.entityId as string
})

const currentEntityPermission = computed(() => {
  return entityStore.entityPermission()
})

const onChangePage = (page: number) => {
  emit('onChangePage', page)
}

const onChangePageSize = (limit: number) => {
  emit('onChangePageSize', limit)
}

const onEditAuxiliaryCodeType = async (row: any) => {
  try {
    if (['MEMBER', ''].includes(currentEntityPermission.value?.role)) {
      ElMessage.warning(t('message.noPermission'))
      return
    }
    currentData.value = row
    emit('onEditAuxiliaryCodeType', row)
  } catch (error) {
    console.error(error)
  }
}
// eslint-disable-next-line no-unused-vars
const onCellClicked = async (row: any, column: any, e: any) => {
  try {
    console.log(row)
  } catch (error: any) {
    ElMessage.error(error.message)
  }
}
const onDeleteAuxiliaryCodeType = (row: any) => {
  if (['MEMBER', ''].includes(currentEntityPermission.value?.role)) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  currentData.value = row
  deleteMessageBoxRef.value?.onCheckMessageBoxDialog()
}

const onCancelDeleteAuxiliaryCodeType = () => {
  deleteMessageBoxRef.value?.onCheckMessageBoxDialog()
}

const onConfirmDeleteAuxiliaryCodeType = async () => {
  try {
    deleteLoading.value = true
    await LedgerApi.deleteAuxiliaryType(entityId.value, currentData.value?.auxiliaryTypeId ?? '')
    deleteMessageBoxRef.value?.onCheckMessageBoxDialog()
    ElMessage.success(t('message.deleteSuccess'))
    emit('onResetList')
  } catch (error: any) {
    console.log(error)
    ElMessage.error(error.message)
  } finally {
    deleteLoading.value = false
  }
}
</script>

<style lang="scss">
.elv-ledger-auxiliary-code-table-container {
  height: calc(100% - 54px);
  width: 100%;
  position: relative;

  .elv-ledger-auxiliary-code-table-box {
    height: calc(100% - 54px);
    width: 100%;
    position: relative;
  }
}

.elv-auxiliary-code-table {
  --el-table-border-color: #d4dce5;
  --el-table-border: 1px solid #edf0f3;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #d4dce6;
  border-right: 0px;
  border-left: 0px;
  border-bottom-width: 1px;

  .el-table__inner-wrapper {
    width: 100%;

    &::after,
    &::before {
      width: 0;
      height: 0;
    }
  }

  &.el-table--border {
    border-bottom-color: #edf0f3;

    &::after,
    &::before {
      width: 0;
      height: 0;
    }
  }

  .el-table__body-wrapper {
    height: 616px;

    .el-scrollbar__view {
      width: 100%;
      margin-bottom: 0px !important;
    }
  }

  .el-table__border-left-patch {
    width: 0;
  }

  .el-table__row {
    &.elv-table__row--striped {
      background: #f9fafb;
    }

    &.is-hover-row td {
      background: #f5f7fa;
    }

    &:hover td {
      background: transparent;
    }
  }

  .elv-auxiliary-code-table-header {
    background: #eef4fb;

    .elv-auxiliary-code-table-header__cell {
      background: #eef4fb;
      height: 36px;
      box-sizing: border-box;
      border-right-color: #e4e7eb;
      border-bottom-color: #ced7e0;
      padding: 0px;

      &.is-center {
        text-align: center;

        .cell {
          justify-content: center;
        }
      }

      &:last-child {
        border-right: 0px;
      }

      &.is-right .cell {
        justify-content: flex-end;
      }

      .cell {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: #666d74;
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-items: center;
      }
    }
  }

  .elv-auxiliary-code-table-row__cell {
    padding: 0;
    height: 42px;

    // &:last-child {
    //   border-right: 0px;
    // }

    &.is-right .cell {
      text-align: right;
    }

    &.is-center .cell {
      text-align: center;
    }

    .cell {
      padding: 0 10px;
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      color: #0e0f11;
      line-height: 16px;
    }

    .elv-auxiliary-code-table-row__cell-activity {
      font-weight: 500;
    }

    &.elv-auxiliary-code-table-row__cell-account {
      .cell {
        white-space: nowrap;

        span {
          color: #838d95;
          font-family: 'Barlow';
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }

    &.elv-auxiliary-code-table-row__cell-name {
      .cell {
        color: #0e0f11;
        font-family: 'Plus Jakarta Sans';
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }

    &.elv-auxiliary-code-table-row__cell-value {
      .cell {
        color: #aaafb6;
        font-family: 'Plus Jakarta Sans';
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        span {
          color: #0e0f11;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }

    &.elv-auxiliary-code-table-row__operating {
      .cell {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;

        svg {
          fill: #838d95;

          &.is-disabled {
            cursor: not-allowed !important;
          }

          &:not(.is-disabled):hover {
            cursor: pointer;
            fill: #1e2024;
          }
        }
      }
    }

    &.el-table-column--selection {
      .cell {
        width: 100%;
        padding: 0px;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.elv-auxiliary-code-tips.el-popper {
  width: 224px;
  padding: 5px 10px 8px 10px;

  .elv-table-tips-content {
    color: #fff;
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    padding: 0px;
    word-break: break-word;
  }
}

.elv-journals-pagination__sizes-popper.el-popper {
  transform: translateY(10.5px);
}
</style>
